import{
  makeResponsive,
  placeTitle,
  createSpace,
  placeQuestion,
  placeComment,
  createAxes,
  writeHTMLText,
  drawPoint,
  setSize,
  labelIt,
  placeMarker,
  drawCircle,
  placeImage,
  placeShuffle,
  placeTest,
  drawArrow,
  drawAngle,
  drawSegment,
  placeBWhite,
  placeWhite,
  placeBCircles,
  placeCircles,
  placeChecks,
  placeCross,
  placeExclaim,
  hoverMe,
  placeLogo,
  drawMarker,
  toggle,
  toggleTF,
  placeFingers,
  placeAnswers,
  drawTriangle,
  placeRedo,
  placeStartOver,
  drawDash,
  placeTapeVert,
  placeTapeHori,
  placeMiddleText,
  placePlay,
  alert
} from '../Utils.js'
const Boxes = {
  box1: function () {
///////////////////////////////////GLOBAL SETTINGS//////////////////////
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.line.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
//////////////////////////////////LOCAL SETTINGS////////////////////////////////
var graph =createSpace(-6,10,-6,10);
makeResponsive(graph);
graph.suspendUpdate();
graph.options.layer['image']=26;
graph.options.layer['text']=2;
graph.options.layer['html']=2;
graph.options.layer['line']=2;
graph.options.layer['point']=10;
graph.options.layer['circle']=1;
graph.options.image.highlight=false;
/*********************GRAPH DIMENSIONS*******************/
const boundingBox = graph.attr.boundingbox;
const positionX = (boundingBox[2]+boundingBox[0])/2;
const positionY = (boundingBox[1]+boundingBox[3])/2;
const height = (boundingBox[1]-boundingBox[3])/2;
/**********************PUT AXES **********************/
//var ax = createAxes(graph);
/***************** PUT AXES TITLE *******************/
//ax[0].setAttribute({name:'X'});
//ax[1].setAttribute({name:'Y'});
/***********************SCORING *******************/
var yourScore =0; var yourMissed =0; var yourWrong =0; var yourTotal=0;
var scoreCard = writeHTMLText(graph, positionX, positionY+1, function(){return 'Your Score ( &check; ): '+ yourScore+'/5'});
var missedCard = writeHTMLText(graph, positionX, positionY, function(){return 'Missed Questions ( ! ): '+ yourMissed+'/5'});
var wrongCard = writeHTMLText(graph, positionX, positionY-1, function(){return 'Wrong Answers ( x ): '+ yourWrong+'/5'});
scoreCard.setAttribute({visible:false});
missedCard.setAttribute({visible:false});
wrongCard.setAttribute({visible:false});
/*************************ANSWERS*********************/
var index_selected_answer = -1;
const XList = [4, 3, 5, 4, 6];
const YList = [1, -1, 2, -2, 0];
var Ptm = drawPoint(graph, XList[0], YList[0]);
Ptm.setAttribute({visible:false});
const answers = [['40', '10', '20', '30'], ['9', '18', '6', '21'],['6', '10', '20', '30'],['16', '8', '6', '12'], ['24', '20', '18', '16']];
const index_right_answer = [2,0,3,1,0];
/********************************************************************************************/
var Pt1 = drawPoint(graph, 0, 0);
setSize(graph, Pt1, 0);
labelIt(graph, Pt1, 'A');
Pt1.label.setAttribute({offset:[0, -20]});

var Pt2 = drawPoint(graph, 2, ()=>4+Ptm.Y());
setSize(graph, Pt2, 0);
labelIt(graph, Pt2, 'C');
Pt2.label.setAttribute({offset:[0, 20]});

var Pt3 = drawPoint(graph, ()=>Ptm.X(), 0);
setSize(graph, Pt3, 0);
labelIt(graph, Pt3, 'B');
Pt3.label.setAttribute({offset:[0, -20]});
//labelIt(graph, Pt3, 'C');

var Pt4 = drawPoint(graph, ()=>2+Ptm.X(), ()=>4+Ptm.Y());
setSize(graph, Pt4, 0);
labelIt(graph, Pt4, 'D');
Pt4.label.setAttribute({offset:[0, 20]});

graph.create('polygon', [Pt1, Pt3, Pt4, Pt2], {visible: true, fixed:true,  vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth:2}, fillColor:'green', fillOpacity:1});

var t1 = drawPoint(graph, 0, -1.5);
setSize(graph, t1, 0);
var t2 = drawPoint(graph, ()=>Pt3.X(), -1.5);
setSize(graph, t2, 0);
var t3 = drawPoint(graph, -1.5, 0);
setSize(graph, t3, 0);
var t4 = drawPoint(graph, -1.5, ()=>Pt2.Y());
setSize(graph, t4, 0);
var horz =placeTapeVert(graph, t3, t4, 'b');
var vert =placeTapeHori(graph, t1, t2, 'h');
/*************************************PRINT ANSWER ****************************************/
//print(graph, ()=> (0.5*(180-2*alpha.Value()*180/Math.PI)).toFixed(1))
/****************PUT TITLE ****************************/
var question = placeQuestion(graph, 'The area of parallelogram ABCD is &mdash;');
var comment = placeComment(graph, '');
var note = writeHTMLText(graph, positionX, positionY+1+height/2., 'You did not make a selection.');
note.setAttribute({visible:false, color:'red'});
//print(graph, ()=>(alpha.Value()*180/Math.PI).toFixed(1));
///////////////////////////GRAPHICS MODULES//////////////////
var bck =placeWhite(graph);
var show =placeBCircles(graph);
var hide=placeCircles(graph);
var check = placeChecks(graph);
var cross = placeCross(graph);
var exclaim = placeExclaim(graph);
var pointers = placeFingers(graph);
hide[0].setAttribute({visible:false});
/***************************BUTTON MODULES******************/
var test =placeTest(graph,'left');
hoverMe(graph, test, 'Check Your Answer', -20, 0);
/**/
var next = placePlay(graph);
hoverMe(graph, next, 'Next Question', 0, 0);
/**/
var redo = placeStartOver(graph);
redo.setAttribute({visible:false});
hoverMe(graph, redo, 'Start Over', 0, 0);
/***************************************/
var i=0; var k=0; var vis=false;
///////////////////////////////////////////////////////////////
var ansList = ['40', '10', '20', '30'];
var ansArray = placeAnswers(graph, ansList);
for(let ij=0; ij<=3; ij++)
{
  ansArray[ij].on('down', function(){
  ansArray.forEach(function(item){item.setAttribute({color:'grey'})});
  pointers.forEach(function(item){item.setAttribute({visible:false})});
  ansArray[ij].setAttribute({color:'black'});
  pointers[ij].setAttribute({visible:true});
  index_selected_answer = ij.valueOf();})
}
////////////////////////////////////////////////////////////////////////
var hint =writeHTMLText(graph, positionX, positionY, '<ol> <li> Tap on <i class="redoer ma-1"></i> to go to next step.</li> <br><li>Tap on <i class="tester ma-1"></i> to check your answer.</li><br><li>Tap on <i class="undoer ma-1"></i> button to start over.</li> </ol>');
hint.setAttribute({visible:false, color:'white'});
/**************************TEST BUTTON ***********************/
test.on('down', function()
{
  note.setAttribute({visible:false});
  if(i<=XList.length-1)
  {
    if(index_selected_answer == index_right_answer[i] && yourTotal<XList.length)
	  { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
      }
      else if(index_selected_answer != index_right_answer[i] && yourTotal<XList.length && index_selected_answer != -1)
      { //is incorrect
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
        ansArray[index_selected_answer].setAttribute({color:'red'});
      }
	  else if (index_selected_answer == -1 && yourTotal<XList.length)
	  {   check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:true});
        alert(graph, note);
	  }
  }
});
/*****************************SHUFFLE BUTTON ***********************/
next.on('down', function(){
  note.setAttribute({visible:false});
  if(i<=XList.length-1)
  {
    if(index_selected_answer == index_right_answer[i] && yourTotal<XList.length)
	  { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        yourScore = yourScore+1;
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
      }
      else if(index_selected_answer != index_right_answer[i] && yourTotal<XList.length && index_selected_answer != -1)
      { //is incorrect
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:true});
        ansArray[index_right_answer[i]].setAttribute({color:'green'});
        ansArray[index_selected_answer].setAttribute({color:'red'});
		    yourWrong = yourWrong+1;
      }
	  else if (index_selected_answer == -1 && yourTotal<XList.length)
	  {   check[i].setAttribute({visible:false});
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:true});
		    yourMissed = yourMissed+1;
	  }
	  yourTotal = yourScore + yourWrong + yourMissed;
  }
  if(i==XList.length-1)
  {
  bck.setAttribute({opacity:1});
  scoreCard.setAttribute({visible:true});
  missedCard.setAttribute({visible:true});
  wrongCard.setAttribute({visible:true});
  redo.setAttribute({visible:true});
  ansList[0]=answers[0][0];
  ansList[1]=answers[0][1];
  ansList[2]=answers[0][2];
  ansList[3]=answers[0][3];
  ansArray.forEach(function(item){item.setAttribute({visible:false})});
  question.setAttribute({visible:false});
  //alpha.setAttribute({visible:false});
  //beta.setAttribute({visible:false});
  }
  else{
    scoreCard.setAttribute({visible:false});
    missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
    i=i+1;
    ansList[0]=answers[i][0];
    ansList[1]=answers[i][1];
    ansList[2]=answers[i][2];
    ansList[3]=answers[i][3];
  }
  hide[i].setAttribute({visible:false});
  hint.setAttribute({visible:false});
  pointers.forEach(function(item){item.setAttribute({visible:false})});
  ansArray.forEach(function(item){item.setAttribute({color:'grey'})});
  index_selected_answer = -1;
  /////////////////////////ALL CHANGES GO HERE////////////////////////
  Ptm.moveTo([XList[i], YList[i]], 200);
  ///////////////////////////////////////////////////////////////////
});
/************************************************/
redo.on('down', function(){
	//Hiding all blue circles
	  hide.forEach(function(item){item.setAttribute({visible:true})});
    hide[0].setAttribute({visible:false});
  //Hiding all check marks
    check.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all cross marks //
    cross.forEach(function(item){item.setAttribute({visible:false})});
  //Hiding all exclaimation marks
	  exclaim.forEach(function(item){item.setAttribute({visible:false})});
  ///////////////resetting scores//////////////////
    note.setAttribute({visible:false});
	  yourScore=0;
	  yourMissed=0;
	  yourWrong=0;
	  yourTotal=0;
	  scoreCard.setAttribute({visible:false});
	  missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
  /////////////////////////////////////////////
	  i=0;
	  ansList[0]=answers[0][0];
	  ansList[1]=answers[0][1];
	  ansList[2]=answers[0][2];
	  ansList[3]=answers[0][3];
	  bck.setAttribute({opacity:0});
	  pointers.forEach(function(item){item.setAttribute({visible:false})});
    ansArray.forEach(function(item){item.setAttribute({visible:true, color:'grey'})});
	  question.setAttribute({visible:true});
    comment.setAttribute({visible:true});
	  hint.setAttribute({visible:false});
	  index_selected_answer = -1;
    redo.setAttribute({visible:false});
    ////////////////////////ALL CHANGES GO HERE/////////////
    Ptm.moveTo([XList[0], YList[0]], 200);
    ////////////////////////////////////////////////////////
    //alpha.setAttribute({visible:true});
    //beta.setAttribute({visible:true});
});
/********************PLACE LOGO ********************/
var edliy = placeLogo(graph);
hoverMe(graph, edliy, 'Tap to see a hint', 0, 0);
edliy.on('down',
function(){
  k = toggle(k);
	vis = toggleTF(vis);
  bck.setAttribute({opacity:k});
	scoreCard.setAttribute({visible:false});
	missedCard.setAttribute({visible:false});
	wrongCard.setAttribute({visible:false});
  hint.setAttribute({visible:vis});
	question.setAttribute({visible:!vis});
	ansArray.forEach(function(item){item.setAttribute({visible:!vis})});
  note.setAttribute({visible:false});
  ////////////////////////Code specific changes go here/////////////////
	//alpha.setAttribute({visible:!vis});
  //beta.setAttribute({visible:!vis});
  //////////////////////////////////////////////////////////////////////
});
graph.unsuspendUpdate();
/******************END OF FILE ***************************/
}
}
export default Boxes;
